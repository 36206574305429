import React from 'react';
import { Helmet } from 'react-helmet';

const SeoComponent = ({ title, description, keywords, canonical, author, ogTitle, ogDescription, ogImage, ogUrl }) => {
  return (
    <Helmet>
      {/* Metaetiquetas básicas */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Enlace canónico (URL canónica) */}
      {canonical && <link rel="canonical" href={canonical} />}

      {/* Autor de la página */}
      {author && <meta name="author" content={author} />}

      {/* Open Graph para redes sociales */}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}

      {/* Otras metaetiquetas SEO según sea necesario */}
    </Helmet>
  );
};

export default SeoComponent;
