import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const MarkdownViewer = ({ markdownContent }) => {

  console.log(markdownContent);
  return (
   <div className="w-full md:w-3/4 p-4 border mt-4 rounded shadow ">
    <Link to="/">Ir a Inicio</Link> 
    <div className='mt-3 prose'><ReactMarkdown>{markdownContent}</ReactMarkdown></div>
    </ div>
  );  
};

export default MarkdownViewer;
