import React,{useState}  from 'react';
import Sketch from 'react-p5';

  
let widthLayout;
let heightLayout;
let canvasInstance; 
 
 
const P5Wrapper = ({ audioContext ,analyser ,fieldsetWidth}) => {  
    const [dataArray, setDataArray] = useState(null);
    const [bufferLength, setBufferLength] = useState(0);
  
 
    const estilo = window.getComputedStyle(fieldsetWidth); 

    widthLayout = fieldsetWidth.clientWidth;
    heightLayout = fieldsetWidth.clientHeight - parseFloat(estilo.paddingTop);
      
     const setup = (p5, canvasParentRef) => { 
        let bufferLength = analyser.frequencyBinCount;
        let dataArray = new Float32Array(bufferLength); 
        setBufferLength(bufferLength);
        setDataArray(dataArray);
       
        canvasInstance = p5.createCanvas(widthLayout, heightLayout).parent(canvasParentRef);  
        // console.log(canvasInstance);
         p5.angleMode(p5.DEGREES);  
      }; 
      
      const draw = (p5) => {
              
         p5.clear();    

         if (dataArray instanceof Float32Array) { 
            analyser.getFloatTimeDomainData(dataArray);
            // p5.translate(widthLayout/2,heightLayout/2); 
             p5.translate(widthLayout/2, heightLayout / 2); // Mover el origen al centro

             //p5.background(0);
             p5.stroke(255, 255, 0);
             p5.strokeWeight(10);
             p5.noFill();  
             for (let t = -1; t <= 1; t+=2) {
               p5.beginShape();  
               for (let i = 0; i <=180; i++) {  
                  let index = p5.floor(p5.map(i, 0, 180, 0,bufferLength - 1))
                  let r = p5.map(dataArray[index], -1, 2, 80, 20);
                  let x = r * p5.sin(i) * t;
                  let y = r * p5.cos(i) ; // Ajuste de la amplitud
                  
                  p5.vertex(x,y); 
                } 
               p5.endShape();
             }
             //p5.noFill();  // Establecer el relleno en transparente
             //p5.stroke(0); // Establecer el color del borde (negro)
             //p5.strokeWeight(2); // Establecer el grosor del borde
 
          } else {
           // console.log('dataArray is not an array');
          }  
    };
    
    
    
      return <Sketch setup={setup} draw={draw} />;
};

export default P5Wrapper;
