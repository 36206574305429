import React from 'react';
import AudioPlayer from './Player';

import CryptoList from './Crypto';

       

const SidebarRight = () => {
  // console.log('sdsdddddddddd');
  //http://149.56.175.167:5429/stream
  //https://listen1.myradio24.com/5967
  return (
<div className="w-full md:w-1/4 p-4">
  <div className='relative h-full'> 
    <CryptoList /> 
    <div className='sticky top-2'> 
       <AudioPlayer /> 
    </div>
    </div>
    </div>
  );
};

export default SidebarRight;
