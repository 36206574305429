import React, { useEffect } from 'react';

const TelegramWidget = ({
  discussion,
  commentsLimit,
  colorful,
  color,
  dark,
  darkColor,
  width,
  userpic,
  telegramDiscussion, // Nuevo parámetro
}) => {
  useEffect(() => {
    // Crear un elemento script
    const Wtelegram = document.createElement('script');

    // Establecer la fuente y otras propiedades del script
    Wtelegram.src = 'https://telegram.org/js/telegram-widget.js?22';
    Wtelegram.async = true;

    // Verificar si cada propiedad está presente antes de establecer la propiedad
    if (discussion) {
      Wtelegram.setAttribute('data-telegram-post', discussion);
    }

    if (commentsLimit) {
      Wtelegram.setAttribute('data-comments-limit', commentsLimit);
    }

    if (colorful) {
      Wtelegram.setAttribute('data-colorful', colorful);
    }

    if (color) {
      Wtelegram.setAttribute('data-color', color);
    }

    if (dark) {
      Wtelegram.setAttribute('data-dark', dark);
    }

    if (darkColor) {
      Wtelegram.setAttribute('data-dark-color', darkColor);
    }

    if (width) {
      Wtelegram.setAttribute('data-width', width);
    }

    if (userpic) {
      Wtelegram.setAttribute('data-userpic', userpic);
    }

    // Verificar y establecer el nuevo parámetro
    if (telegramDiscussion) {
      Wtelegram.setAttribute('data-telegram-discussion', telegramDiscussion);
    }

    // Obtener el contenedor específico por su ID
    const container = document.getElementById('telegram-widget-container');

    // Verificar que el contenedor exista antes de anexar el script
    if (container) {
      // Anexar el script al contenedor
      container.appendChild(Wtelegram);

      // Limpiar: eliminar el script cuando el componente se desmonta
      return () => {
        container.removeChild(Wtelegram);
      };
    }
  }, [discussion, commentsLimit, colorful, color, dark, darkColor, width, userpic, telegramDiscussion]);

  return <div id="telegram-widget-container" className="w-100"></div>;
};

export default TelegramWidget;
