// components/Modal.js
import { useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalStyles = `
    ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
    fixed inset-0 z-50 overflow-auto
    flex items-center justify-center
    bg-modal
  `;

  const modalContentStyles = `
    bg-white w-11/12 max-w-md mx-auto
    p-4 rounded shadow-md text-zinc-500
  `;

  return isBrowser ? (
    <div className={modalStyles}>
      <div className={modalContentStyles}>
        <button onClick={handleClose} className="absolute top-0 right-0 p-4">
          <svg
            className="w-6 h-6 text-gray-700"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
