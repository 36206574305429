import React, { useRef, useEffect, useState} from 'react';
 import P5Wrapper from './P5Wrapper' 

// Helper function to format time in MM:SS format
const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
 
const centerDivStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',   
  width:'80px',
  height:'80px',
};
const NestedRadioList = ({play,onAudioChange}) => {
  const [selectedStationId, setSelectedStationId] = useState(null);

  const radioStations = [
    {
      id: 1,
      name: "Vipradio",
      detail: "Eurodance - EDM - Trance",
      listen: "http://149.56.175.167:5429/stream"
    },
    {
      id: 2,
      name: "Eurodance",
      detail: "Eurodance",
      listen: "https://listen1.myradio24.com/5967"
    },
    {
      id: 3,
      name: "Raute Musik HappyHardcore",
      detail: "The ultimate destination for hardcore music lovers",
      listen: "https://happyhardcore-high.rautemusik.fm/stream.mp3"
    },
    {
      id: 4,
      name: "El Bachatazo Radio Show",
      detail: "Bachata music adventure",
      listen: "https://stream.zenolive.com/wzdgb910t9duv"
    },
    {
      id: 5,
      name: "TDI Rradio",
      detail: "TDI Rradio je No.1 Hit radio stanica u Srbiji. Možete ",
      listen: "http://streaming.tdiradio.com:8000/tdiradio"
    }
    ,
    {
      id: 6,
      name: "Love Radio",
      detail: "Musica Fresca y Romantica en Ingles ",
      listen: "https://antena.albaniadigitalradio.al/loveradio"
    }


  ];
  return (
    <div className="bg-stone-950 mx-auto mt-2 rounded shadow border border-neutral-700">
     <div className='playlist divide-y divide-neutral-700'> 
      {radioStations.map(station => (
         <div
          key={station.id}
          href={station.listen}
          className={`${
            selectedStationId === station.id ? 'bg-zinc-800' : 'hover:bg-neutral-900'
          } cursor-pointer`}
          onClick={(e) => {
            e.preventDefault();
            onAudioChange(station.listen, station.id);
            setSelectedStationId(station.id);
          }}
        >
          <div className='p-3'>
          <div className="flex items-center justify-between space-x-4">
            <span className="font-semibold">{station.name}</span>
            <span  className="bg-green-500  rounded px-2">{selectedStationId === station.id ? play : 'Play' } </span>
          </div>
          <span className="text-sm text-gray-400">{station.detail}</span>
          </div>
        </div>
      ))} 
    </div>
  </div>
  );
};   
const AudioPlayer = () => {
  const audioRef = useRef(null);   
  const fieldsetRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);  
  const [fieldsetWidth, setFieldsetWidth] = useState(0);
  const [audioUrl, setAudioUrl] = useState("https://happyhardcore-high.rautemusik.fm/stream.mp3"); // Add this state
  const playButtonText = isPlaying ? `${formatTime(elapsedTime)}` : 'Play';

  // Agrega un useEffect para escuchar cambios en audioUrl

  useEffect(() => {
    const audioElement = audioRef.current;
    // console.log('as');
    if (fieldsetRef.current) {
      setFieldsetWidth(fieldsetRef.current);
    }
    // Evento paffta pausar el audio
    const handlePause = () => {
      setIsPlaying(false);
     // console.log('Audio pausado');
    };

    // Evento para reproducir el audio
    const handlePlay = () => {
      setIsPlaying(true);

      // Check if audioContext and analyser already exist
      if (!audioContext || !analyser) {
        const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        const newAnalyser = newAudioContext.createAnalyser();
              newAnalyser.fftSize = 1024;//1024 32 64 128
              newAnalyser.connect(newAudioContext.destination); 
              setAnalyser(newAnalyser);
        
        const source = newAudioContext.createMediaElementSource(audioElement);
              source.connect(newAnalyser);
              setAudioContext(newAudioContext);
       // console.log('Audio Context and Analyser created.');
      }
     // console.log('Audio reproduciéndose');
    };

    // Evento para cambiar el volumen del audio
    const handleVolumeChange = () => {
      const newVolume = audioElement.volume;
      setVolume(newVolume);
     // console.log('Volumen cambiado:', newVolume);
    };

    // Evento para actualizar el tiempo transcurrido
    const handleTimeUpdate = () => {
      setElapsedTime(audioElement.currentTime);
    };

    audioElement.addEventListener('pause', handlePause);
    audioElement.addEventListener('play', handlePlay);
    audioElement.addEventListener('volumechange', handleVolumeChange);
    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      // Limpia los eventos cuando el componente se desmonta
      audioElement.removeEventListener('pause', handlePause);
      audioElement.removeEventListener('play', handlePlay);
      audioElement.removeEventListener('volumechange', handleVolumeChange);
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioContext, analyser]);

  const handlePlayButton = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    } 
  }; 
  const handleAudioChange = (newAudioUrl) => {
    setAudioUrl(newAudioUrl); 
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.src = newAudioUrl; 
      audioRef.current.oncanplay = () => {
        audioRef.current.play(); 
      };
    } else {
      audioRef.current.src = newAudioUrl; 
      audioRef.current.play();
    } 
  };
  const backgroundStyle = {
    backgroundImage: 'url("./player.png")', // Ruta de tu imagen de fondo
    backgroundSize: 'cover', // Ajusta el tamaño de la imagen para cubrir todo el fondo
    backgroundPosition: 'center', 
  };
  return (<>
    <div className='border rounded shadow' style={backgroundStyle}> 
    <div className='relative player-overlay rounded p-3 text-white'> 
        <div className='player'>
        <div className='relative'>
            <div ref={fieldsetRef} style={{ height: '200px' }}>
            {audioContext && analyser ? <P5Wrapper audioContext={audioContext} analyser={analyser} fieldsetWidth={fieldsetWidth} /> : null}
            </div>
            <small className='absolute  bottom-0'> 
            Playing: {isPlaying.toString()}<br />
            Tiempo transcurrido: {formatTime(elapsedTime)}<br />
            Volumen: {volume} 
            </small>   
                  <button style={centerDivStyle}
              onClick={handlePlayButton}
              className="w-full border playbutton rounded-full">
              {playButtonText}
            </button>   
        </div> 
        </div> 
        <audio ref={audioRef} controls crossOrigin="anonymous" style={{ display: 'none' }}>
          <source src={audioUrl} type="audio/mpeg" />
          Tu navegador no soporta la reproducción de audio en HTML5.
        </audio>
      <input className='w-full'
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={(e) => {
            audioRef.current.volume = e.target.value;
            setVolume(e.target.value);
          }}
        />
      
    </div>
    
    </div>
    <NestedRadioList play={playButtonText} onAudioChange={handleAudioChange}/>
    </>
  );
};

export default AudioPlayer;
