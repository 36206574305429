
import React from 'react';
import AudioPlayer from './Player';

import CryptoList from './Crypto';
import BitcoinBalance from './BitcoinBalance';
import Modal from './Modal';
import { useState } from 'react';
import TelegramWidget from './TelegramWidget';
import SeoComponent from './Seo';
const Noapi = () => {
  //const address = 'bc1qrz8mhsv4ppdss5wjtdlkgg529ek72eah2p4y3g';
  //<BitcoinBalance address={address}/>
  // console.log('sdsdddddddddd');
  //http://149.56.175.167:5429/stream
  //https://listen1.myradio24.com/5967

  const currentYear = new Date().getFullYear();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
<div className="grid justify-items-center w-full">
  <div className='relative customContent'> 
    <div className='bg'>
      <h1 className='glitch text-center'>NEGROSERIAS</h1> 
    </div>
    <div>
     
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {/* Contenido del modal */}
        <p>A partir de hoy, el acceso solo es a través de donaciones. </p>
        <p>

        </p>
        <p>
        Envianos un email con los detalles de tu donacion a 
        
        </p>
        <strong>donaciones@negroserias.org  </strong>

        <p>
          <strong>Correo:</strong> Email de tu cuenta mega.nz <br></br>
          <strong>Metodo de donacion:</strong>btc,zec,monero, ETC (captura) <br>
          </br>
          <small>Agrega como contacto en tu cuenta mega.nz el correo que esta en la descripción de nuestro canal <a href='https://t.me/n3groserias' className='text-red-800 font-bold'>@n3groserias</a></small>
        </p>
        
        <button onClick={handleCloseModal} className="bg-red-500 text-white p-2 mt-4 rounded text-xs">
          Cerrar  
        </button>
      </Modal>
    </div>

 <button  onClick={handleOpenModal}  className='mb-2 text-slate-950 w-full bg-yellow-500  text-center   block mt-3 rounded p-3 hover:bg-yellow-400 text-3xl font-bold cursor-pointer' >ACCEDER </button>
    <div className='text-white'> 
    <AudioPlayer />
    <div className='mt-2'>
    <TelegramWidget
      discussion="VoltSync/5"
      width="100%"
      dark="1"
      userpic={true}
    />
    </div>
    </div>
    </div>
    <div className='mt-2 text-center'> 
    <CryptoList /> 
    </div>
    <hr className='w-100 border-t-2 border-red-300'></hr>
    <p><a href='https://www.youtube.com/results?search_query=comprar+crypto+en+mi+pais' target='_blank'>¿Tutorial sobre crypto aquí?</a></p> 
    
    <p className='mt-2'><a  className='text-yellow-400' href='https://t.me/n3groserias' target='_blank'>Únete a nuestro canal @n3groserias en telegram</a></p>
    
    
    <div className='customContent w-full'>
    <TelegramWidget
      telegramDiscussion="VoltSync/5"
      commentsLimit = "50"
      width="100%"
      colorful ="1"
      color="CA9C0E"
      dark="1"
      userpic={true}
    />
    </div>
    <p className='my-4'>Make with ❤️  {currentYear}</p>
    <SeoComponent
        title="Negroserias -  Música"
        description="Descubre y comparte tu pasión por la música. Únete a nuestro portal para explorar diversos géneros musicales, conocer nuevos artistas como Armin van Buuren en trance y Daft Punk en electro, y participar en conversaciones apasionadas sobre temas populares como 'Strobe' y 'Adagio for Strings'."
        keywords="música, géneros musicales, trance, electro, Armin van Buuren, Daft Punk, Strobe, Adagio for Strings, música en línea, comunidad musical"
        canonical="https://negroserias.org"
        author="Tu Nombre"
        ogTitle="Portal de Música"
        ogDescription="Descubre y comparte tu pasión por la música. Únete a nuestro portal para explorar diversos géneros musicales, conocer nuevos artistas como Armin van Buuren en trance y Daft Punk en electro, y participar en conversaciones apasionadas sobre temas populares como 'Strobe' y 'Adagio for Strings'."
        ogImage="https://negroserias.org/og-image.jpg"
        ogUrl="https://negroserias.org"
      />
    </div>
  );
};

export default Noapi;
